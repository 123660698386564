import React from "react";
import PropTypes from "prop-types";
import { HTMLContent } from "../components/Content";

import VideoEmbed from "../components/VideoEmbed";

class FilmPost extends React.Component {
  render() {
    const { video, thumbnail, title, venue, content } = this.props;

    return (
      <div>
        <div className="reel__video">
          <VideoEmbed url={video} thumbnail={thumbnail} />
        </div>
        <strong>{title}</strong> - <span>{venue}</span>
        <HTMLContent className="content" content={content} />
      </div>
    );
  }
}

FilmPost.propTypes = {
  video: PropTypes.string,
  thumbnail: PropTypes.object,
  title: PropTypes.string,
  venue: PropTypes.string,
  content: PropTypes.string,
};

export default FilmPost;
