import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import FilmReel from '../components/FilmReel'
import Content, { HTMLContent } from '../components/Content'

const FilmsPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <h1>{title}</h1>
      <PageContent className="content" content={content} />
      <FilmReel />
    </div>
  )
}

FilmsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const FilmsPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <FilmsPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

FilmsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FilmsPage

export const filmsPageQuery = graphql`
  query FilmsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
