import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";

import FilmPost from "../components/FilmPost";

class FilmReel extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className="filmreel">
        {posts &&
          posts.map(({ node: post }) => (
            <FilmPost
              key={post.id}
              video={post.frontmatter.video}
              thumbnail={post.frontmatter.thumbnail}
              title={post.frontmatter.title}
              venue={post.frontmatter.venue}
              content={post.html}
            />
          ))}
      </div>
    );
  }
}

FilmReel.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const filmReel = () => (
  <StaticQuery
    query={graphql`
      query FilmReelQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "featured-film" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                venue
                video
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 1500, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              html
            }
          }
        }
      }
    `}
    render={(data, count) => <FilmReel data={data} count={count} />}
  />
);

export default filmReel;
