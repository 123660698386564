import youtubeRegex from "youtube-regex";
import vimeoRegex from "vimeo-regex";
import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

import "../styles/components/VideoEmbed.scss";

const VIMEO = "VIMEO";
const YOUTUBE = "YOUTUBE";

function getVideoType(url) {
  if (vimeoRegex().test(url)) {
    return VIMEO;
  }
  if (youtubeRegex().test(url)) {
    return YOUTUBE;
  }
  return null;
}

function getVideoUrl(url, type) {
  switch (type) {
    case VIMEO:
      return `//player.vimeo.com/video/${vimeoRegex().exec(url)[4]}`;
    case YOUTUBE:
      return `https://www.youtube.com/embed/${youtubeRegex().exec(url)[1]}`;
    default:
      return null;
  }
}

class VideoEmbed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoType: getVideoType(props.url),
      showVideo: props.autoplay,
    };
  }

  playVideo = () => {
    this.setState({ showVideo: true });
  };

  render() {
    const { url, autoplay, fullscreen, thumbnail } = this.props;
    const { videoType, showVideo } = this.state;
    const qs = new URLSearchParams();
    const videoSrc = getVideoUrl(url, videoType);
    const className = `video ${fullscreen ? "fullscreen" : ""}`;

    if (autoplay) {
      qs.set("autoplay", 1);
      if (videoType === VIMEO) {
        qs.set("background", 1);
      } else if (videoType === YOUTUBE) {
        qs.set("mute", 1);
      }
    }

    const videoMarkup = (
      <iframe title="video" src={`${videoSrc}?${qs}`} allowFullScreen></iframe>
    );
    const thumbnailMarkup = (
      <div className="play-video">
        {thumbnail ? (
          <PreviewCompatibleImage
            imageInfo={{ image: thumbnail, alt: "play" }}
          />
        ) : null}
        <button className="play-video__button" onClick={this.playVideo}>
          Play Video
        </button>
        <svg
          className="play-video__icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="29 26 42 47"
        >
          <path d="M34.79 73.09a5 5 0 01-2.5-.67 5.17 5.17 0 01-2.6-4.53V32.11a5.17 5.17 0 012.6-4.53 5 5 0 015.05 0L67.76 45.5a5.25 5.25 0 010 9L37.34 72.39a5 5 0 01-2.55.7z" />
        </svg>
      </div>
    );

    return videoSrc ? (
      <div className={className}>
        {showVideo ? videoMarkup : thumbnailMarkup}
      </div>
    ) : null;
  }
}

VideoEmbed.propTypes = {
  fullscreen: PropTypes.bool,
  params: PropTypes.object,
  autoplay: PropTypes.bool,
  thumbnail: PropTypes.object,
  url: PropTypes.string,
};

export default VideoEmbed;
